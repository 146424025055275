import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";

export const useWhen = () => {
  
  const theme = useTheme();
  const matchesTab = useMediaQuery( theme.breakpoints.up('tablet'))
  
  const [zoom, setZoom] = useState(false);

  const images = [
    {
      original: '/place_1.webp',
      thumbnail: './place_1.jpg',
      originalWidth: 550,
      originalHeight: 400,
    },{
      original: '/ms1.webp',
      thumbnail: './ms1_200.jpg',
      originalWidth: 550,
      originalHeight: 400,
    },
    {
      original: '/ms2.webp',
      thumbnail: './ms2_150.jpg',
      originalWidth: 550,
      originalHeight: 400,
    },
    {
      original: '/ms3.webp',
      thumbnail: './ms3_150.jpg',
      originalWidth: 550,
      originalHeight: 400,
    },
    {
      original: '/ms5_1200.webp',
      thumbnail: './ms5_150.jpg',
      originalWidth: 550,
      originalHeight: 400,
    },
    {
      original: '/ms6_1200.webp',
      thumbnail: './ms6_150.jpg',
      originalWidth: 550,
      originalHeight: 400,
    },
  ]

  const handleZoom = () => {
    setZoom(!zoom);
  }

  return {
    zoom,
    images,
    matchesTab,
    handleZoom,
  }
}
