import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import { Background, Parallax } from "react-parallax";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube, Zoom, Pagination, Navigation, Autoplay } from "swiper";
import Image from "next/image";

import { useWhen } from "@/hooks/useWhen";
import { ephesis } from "@/utils/fonts";
import useBreakpoints from "@/hooks/useBreakpoints";

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/effect-cube";
import "swiper/css/pagination";

const When = () => {
  const { zoom, images, matchesTab, handleZoom } = useWhen();
  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Parallax strength={800}>
      <Background className={"parallaxImage"}>
        <Image
          alt="When parallax Image"
          src={
            smartphone || mobile || tablet
              ? "/ms_and_mrs_mobile.webp"
              : "/vocho_desk.webp"
          }
          loading={"lazy"}
          fill
          quality={100}
          style={{
            objectFit: "cover",
          }}
        />
      </Background>
      <Box
        height={matchesTab ? "78vh" : "100vh"}
        display={"flex"}
        flexDirection={matchesTab ? "row" : "column"}
        justifyContent={"center"}
        padding={"1vh"}
        alignItems={"center"}
        gap={"1vh"}
      >
        <Card className="glass">
          <CardHeader
            title={"Mansión Sunset"}
            sx={{
              paddingY: "0",
            }}
            titleTypographyProps={{
              sx: {
                textAlign: "center",
                color: "var(--color-primary-4)",
                fontSize: {
                  xs: "6.5vw",
                  tablet: "5vw",
                  lg: "4vw",
                  xl: "3vw",
                },
              },
              className: ephesis.className,
            }}
          />
          <CardContent
            sx={{
              alignSelf: "center",
              width: "100%",
              height: "100%",
              paddingY: ".5vh",
            }}
          >
            <Swiper
              className={"whenSwiper"}
              effect={"cube"}
              centeredSlides={true}
              slidesPerView={"auto"}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              cubeEffect={{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
              }}
              zoom={true}
              navigation={true}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[EffectCube, Zoom, Navigation, Autoplay, Pagination]}
            >
              {images &&
                images.map((item) => {
                  return (
                    <SwiperSlide
                      className={"whenSwiperSlide"}
                      key={item.original}
                    >
                      <div className="swiper-zoom-container">
                        <Image
                          src={item.original}
                          alt={item.original}
                          fill
                          quality={100}
                          onDoubleClick={handleZoom}
                          style={{
                            cursor: zoom ? "zoom-in" : "zoom-out",
                          }}
                          loading={"lazy"}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </CardContent>
        </Card>

        <Card className="glass">
          <CardHeader
            title="Ubicación"
            sx={{
              paddingY: "0",
            }}
            titleTypographyProps={{
              sx: {
                textAlign: "center",
                color: "var(--color-primary-4)",
                fontSize: {
                  xs: "6.5vw",
                  tablet: "5vw",
                  lg: "4vw",
                  xl: "3vw",
                },
              },
              className: ephesis.className,
            }}
          />
          <CardContent
            sx={{
              alignSelf: "center",
              width: "100%",
              height: "100%",
              paddingY: ".5vh",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d954.3587255461892!2d-99.9767710714808!3d16.903826282151147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDU0JzEzLjgiTiA5OcKwNTgnMzQuMSJX!5e0!3m2!1ses-419!2smx!4v1681853273978!5m2!1ses-419!2smx"
              style={{
                border: 0,
                borderRadius: "15px",
                width: "100%",
                height: "100%",
              }}
              loading="lazy"
              title="ubicacion"
            ></iframe>
          </CardContent>
        </Card>
      </Box>
    </Parallax>
  );
};

export default When;
